var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"advertising relative mx-auto text-center w-full",class:[
		..._vm.outerElementAdClasses,
		{ 'advertising--debug': _vm.isDebugEnabled },
	],style:({
		top: _vm.isSticky && `${_vm.stickyTop}px`,
	})},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShowTag),expression:"shouldShowTag"}],staticClass:"tag"},[_vm._v("Advertisement")]),_vm._v(" "),_c('ClientOnly',[(_vm.isDebugEnabled)?_c('div',{staticClass:"debug-box",domProps:{"innerHTML":_vm._s(_vm.debugDataAsHTML)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"wordfinder-ad wordfinder-ad--aditude",class:_vm.innerElementAdClasses,style:({
			top: _vm.innerElementIsScrollable && `${_vm.stickyTop}px`,
		}),attrs:{"id":_vm.aditudeId}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }