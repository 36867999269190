
import { AD_LAYOUT_STUBS, AD_PLACEMENTS, PAGES } from "@/common/consts";
import { isResultsPage } from "@/common/utils";

export default {
	name: "BaseLayout",
	props: {
		isErrorLayout: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			AD_LAYOUT_STUBS,
			AD_PLACEMENTS,
		};
	},
	head() {
		return {
			title: this.$t(this.$route.meta.title),
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.$t(this.$route.meta.description),
				},
			],
		};
	},
	computed: {
		showExcoAd() {
			if (!isResultsPage(this.$route.name)) {
				return false;
			}

			if (this.isFeatureEnabled("ExcoOverride")) {
				return true;
			}

			const userLocation = this.$store.state?.account?.location || "";
			if (!["US", "CA", "GB"].includes(userLocation)) {
				return false;
			}

			if (this.isSmallDevice && !this.isFeatureEnabled("ExcoMobile")) {
				return false;
			}

			if (
				this.isMediumDeviceOrWider &&
				!this.isFeatureEnabled("ExcoDesktop")
			) {
				return false;
			}

			return true;
		},
		showLeaderboardAd() {
			if (
				(this.$route.name === PAGES.ARTICLE_DETAIL &&
					this.$store.state.articles.hideAds) ||
				this.$route.name === PAGES.GET_THE_APP
			) {
				return false;
			}

			return true;
		},
		showMobileTopBanner() {
			return [PAGES.WORDLE_HINTS, PAGES.WORDLE_ANSWERS].includes(
				this.$route.name
			);
		},
		headerClearanceHeight() {
			return this.$store.getters["window/headerClearanceHeight"];
		},

		renderMobileAdhesionAd() {
			// If it's an article page and it's an article that specifically hides ads, do not show the adhesion ad.
			if (
				this.$route.name === PAGES.ARTICLE_DETAIL &&
				this.$store.state.articles.hideAds
			) {
				return false;
			}

			// Do not show it on the Get the App page
			if (this.$route.name === PAGES.GET_THE_APP) {
				return false;
			}

			// Do not show it if the Exco ad is in sticky mode.
			if (this.$store.state.ads.isExcoSticky === true) {
				return false;
			}

			return true;
		},
		renderDesktopAdhesionAd() {
			if (
				[PAGES.WORDLE_HINTS, PAGES.WORDLE_ANSWERS].includes(
					this.$route.name
				)
			) {
				return true;
			}

			return false;
		},
		showLeaderboardAdWrapper() {
			return this.$route.name !== PAGES.GET_THE_APP;
		},
		renderAdhesionAdWrapper() {
			return this.renderMobileAdhesionAd || this.renderDesktopAdhesionAd;
		},
		showAdhesionAdWrapper() {
			/**
			 * We also do not want to show the adhesion ad at all
			 * if it's a mobile device that is short (i.e. the keyboard is open)
			 */
			if (
				this.$store.state.window.windowHeight !== null &&
				this.$store.state.window.windowHeight < 500 &&
				this.isSmallDevice
			) {
				return false;
			}

			return true;
		},
		showBreadcrumbsOnMobile() {
			return [PAGES.ABOUT].includes(this.$route.name);
		},
		breadcrumbItems() {
			const overrides =
				this.$store.state.navigation?.breadcrumbsOverride || [];
			if (overrides.length) {
				return overrides;
			}
			return this.$route.meta?.breadcrumbs || [];
		},
	},
};
