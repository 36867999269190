/**
 * @note
 * This is the full list of menu items for both menu and desktop.
 *
 * Set either `mobileOnly` or `desktopOnly` to `true` to make it exclusive
 * to either one category (tablet devices are considered mobile). You can
 * also apply this to submenu items.
 *
 * For items with submenus, there are 2 additional properties for desktop.
 * `submenuRows` determines how many rows per column are in the submenu.
 * `submenuRightOffset` helps adjust the position of the dropdown.
 */
export const MENU_ITEMS = [
	{
		labelDictionaryKey: "HeaderNYTConnectionsText",
		linkDictionaryKey: "HeaderNYTConnectionsUrl",
		attrDictionaryKey: "HeaderNYTConnectionsTitleAttribute",
		isNew: true,
		useRouter: true,
	},
	{
		labelDictionaryKey: "HeaderSection2Text",
		linkDictionaryKey: "HeaderSection2Url",
		attrDictionaryKey: "HeaderSection2TitleAttribute",
		cssClass: "has-hint",
		useRouter: true,
		navLink: true,
	},
	{
		labelDictionaryKey: "HeaderSection3Text",
		linkDictionaryKey: null,
		attrDictionaryKey: "HeaderSection3TitleAttribute",
		cssClass: "has-hint",
		navLink: true,
		submenuIsOpen: false,
		submenuRows: 8,
		submenuRightOffset: -290,
		submenu: [
			{
				labelDictionaryKey: "HeaderSection3Subsection1Text",
				linkDictionaryKey: "HeaderSection3Subsection1Url",
				attrDictionaryKey: "HeaderSection3Subsection1TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection2Text",
				linkDictionaryKey: "HeaderSection3Subsection2Url",
				attrDictionaryKey: "HeaderSection3Subsection2TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection1Text",
				linkDictionaryKey: "HeaderSection1Url",
				attrDictionaryKey: "HeaderSection1TitleAttribute",
				isHot: true,
			},
			{
				labelDictionaryKey: "HeaderWordleAnswersText",
				linkDictionaryKey: "HeaderWordleAnswersUrl",
				attrDictionaryKey: "HeaderWordleAnswersTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection3Text",
				linkDictionaryKey: "HeaderSection3Subsection3Url",
				attrDictionaryKey: "HeaderSection3Subsection3TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection4Text",
				linkDictionaryKey: "HeaderSection3Subsection4Url",
				attrDictionaryKey: "HeaderSection3Subsection4TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection5Text",
				linkDictionaryKey: "HeaderSection3Subsection5Url",
				attrDictionaryKey: "HeaderSection3Subsection5TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection6Text",
				linkDictionaryKey: "HeaderSection3Subsection6Url",
				attrDictionaryKey: "HeaderSection3Subsection6TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection7Text",
				linkDictionaryKey: "HeaderSection3Subsection7Url",
				attrDictionaryKey: "HeaderSection3Subsection7TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection8Text",
				linkDictionaryKey: "HeaderSection3Subsection8Url",
				attrDictionaryKey: "HeaderSection3Subsection8TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection9Text",
				linkDictionaryKey: "HeaderSection3Subsection9Url",
				attrDictionaryKey: "HeaderSection3Subsection9TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderRandomWordGeneratorText",
				linkDictionaryKey: "HeaderRandomWordGeneratorUrl",
				attrDictionaryKey: "HeaderRandomWordGeneratorTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSpellingBeeText",
				linkDictionaryKey: "HeaderSpellingBeeUrl",
				attrDictionaryKey: "HeaderSpellingBeeTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderScrabbleCheatText",
				linkDictionaryKey: "HeaderScrabbleCheatUrl",
				attrDictionaryKey: "HeaderScrabbleCheatTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderWordleHintsText",
				linkDictionaryKey: "HeaderWordleHintsUrl",
				attrDictionaryKey: "HeaderWordleHintsTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderContextoText",
				linkDictionaryKey: "HeaderContextoUrl",
				attrDictionaryKey: "HeaderContextoTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderCrosswordSolverText",
				linkDictionaryKey: "HeaderCrosswordSolverUrl",
				attrDictionaryKey: "HeaderCrosswordSolverTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderTextWordWars",
				linkDictionaryKey: "HeaderUrlWordWars",
				attrDictionaryKey: "HeaderTitleAttributeWordWars",
			},
			{
				labelDictionaryKey: "HeaderNYTConnectionsTodaysAnswersText",
				linkDictionaryKey: "HeaderNYTConnectionsTodaysAnswersUrl",
				attrDictionaryKey:
					"HeaderNYTConnectionsTodaysAnswersTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderWordSearchText",
				linkDictionaryKey: "HeaderWordSearchUrl",
				attrDictionaryKey: "HeaderWordSearchTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderStrandsText",
				linkDictionaryKey: "HeaderStrandsUrl",
				attrDictionaryKey: "HeaderStrandsTitleAttribute",
				isNew: true,
			},
			{
				labelDictionaryKey: "HeaderSection3Subsection13Text",
				linkDictionaryKey: "HeaderSection3Subsection13Url",
				attrDictionaryKey: "HeaderSection3Subsection13TitleAttribute",
			},
		],
	},
	{
		labelDictionaryKey: "HeaderSection4Text",
		linkDictionaryKey: null,
		attrDictionaryKey: "HeaderSection4TitleAttribute",
		navLink: true,
		submenuIsOpen: false,
		submenuRows: 5,
		submenuRightOffset: -274,
		submenu: [
			{
				labelDictionaryKey: "HeaderSection4Subsection1Text",
				linkDictionaryKey: "HeaderSection4Subsection1Url",
				attrDictionaryKey: "HeaderSection4Subsection1TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection2Text",
				linkDictionaryKey: "HeaderSection4Subsection2Url",
				attrDictionaryKey: "HeaderSection4Subsection2TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection3Text",
				linkDictionaryKey: "HeaderSection4Subsection3Url",
				attrDictionaryKey: "HeaderSection4Subsection3TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection4Text",
				linkDictionaryKey: "HeaderSection4Subsection4Url",
				attrDictionaryKey: "HeaderSection4Subsection4TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderTextFiveLetterWords",
				linkDictionaryKey: "HeaderUrlFiveLetterWords",
				attrDictionaryKey: "HeaderTitleAttributeFiveLetterWords",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection5Text",
				linkDictionaryKey: "HeaderSection4Subsection5Url",
				attrDictionaryKey: "HeaderSection4Subsection5TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection6Text",
				linkDictionaryKey: "HeaderSection4Subsection6Url",
				attrDictionaryKey: "HeaderSection4Subsection6TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection7Text",
				linkDictionaryKey: "HeaderSection4Subsection7Url",
				attrDictionaryKey: "HeaderSection4Subsection7TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection8Text",
				linkDictionaryKey: "HeaderSection4Subsection8Url",
				attrDictionaryKey: "HeaderSection4Subsection8TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderWordleWordListsText",
				linkDictionaryKey: "HeaderWordleWordListsUrl",
				attrDictionaryKey: "HeaderWordleWordListsTitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection9Text",
				linkDictionaryKey: "HeaderSection4Subsection9Url",
				attrDictionaryKey: "HeaderSection4Subsection9TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection10Text",
				linkDictionaryKey: "HeaderSection4Subsection10Url",
				attrDictionaryKey: "HeaderSection4Subsection10TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection11Text",
				linkDictionaryKey: "HeaderSection4Subsection11Url",
				attrDictionaryKey: "HeaderSection4Subsection11TitleAttribute",
			},
			{
				labelDictionaryKey: "HeaderSection4Subsection12Text",
				linkDictionaryKey: "HeaderSection4Subsection12Url",
				attrDictionaryKey: "HeaderSection4Subsection12TitleAttribute",
			},
		],
	},
	{
		labelDictionaryKey: "HeaderSection5Text",
		linkDictionaryKey: "HeaderSection5Url",
		attrDictionaryKey: "HeaderSection5TitleAttribute",
		useRouter: true,
		navLink: true,
	},
];
