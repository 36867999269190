const middleware = {}

middleware['clean-navigation-status'] = require('../middleware/clean-navigation-status.js')
middleware['clean-navigation-status'] = middleware['clean-navigation-status'].default || middleware['clean-navigation-status']

middleware['clean-results-urls'] = require('../middleware/clean-results-urls.js')
middleware['clean-results-urls'] = middleware['clean-results-urls'].default || middleware['clean-results-urls']

middleware['clear-saved-results'] = require('../middleware/clear-saved-results.js')
middleware['clear-saved-results'] = middleware['clear-saved-results'].default || middleware['clear-saved-results']

middleware['crossword-publisher'] = require('../middleware/crossword-publisher.js')
middleware['crossword-publisher'] = middleware['crossword-publisher'].default || middleware['crossword-publisher']

middleware['hybrid-redirection'] = require('../middleware/hybrid-redirection.js')
middleware['hybrid-redirection'] = middleware['hybrid-redirection'].default || middleware['hybrid-redirection']

middleware['set-current-route'] = require('../middleware/set-current-route.js')
middleware['set-current-route'] = middleware['set-current-route'].default || middleware['set-current-route']

middleware['set-dictionary'] = require('../middleware/set-dictionary.js')
middleware['set-dictionary'] = middleware['set-dictionary'].default || middleware['set-dictionary']

middleware['trailing-slash'] = require('../middleware/trailing-slash.js')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

export default middleware
